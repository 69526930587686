/* eslint-disable react/display-name */
/* eslint-disable new-cap */
/* eslint-disable react/prop-types */
import React, {
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Radio,
  Space,
  Typography,
} from 'antd';

const { Title, Text } = Typography;

const PermissionContext = React.createContext(null);

function Input(props) {
  const {
    placeholder, value, onChange,
  } = props;

  const permission = useContext(PermissionContext);
  console.log('input :>> ', permission);

  return (
    <input placeholder={placeholder} value={value} onChange={e => onChange(e.target.value)} />
  );
}
Input.displayName = 'input';

function FormItem(props) {
  const {
    children,
    label,
    name,
    value,
    handleChange,
  } = props;

  function onChange(value) {
    handleChange(name, value);
  }

  return (
    <div>
      <span>{label}</span>
      {
        React.isValidElement(children) && children.type.displayName === 'input'
          ? React.cloneElement(children, {
            value,
            onChange,
          })
          : null
      }
    </div>
  );
}
FormItem.displayName = 'formItem';

class Form extends React.Component {
  state = { formData: {} };

  handleSubmit(cb) {
    const { formData } = this.state;
    cb({ ...formData });
  }

  handleReset() {
    const { formData } = this.state;
    Object.keys(formData).forEach(key => {
      formData[key] = '';
    });
    this.setState({ formData });
  }

  setValue = (key, value) => {
    const { formData } = this.state;
    formData[key] = value;
    this.setState({ formData });
  };

  render() {
    const { formData } = this.state;
    const { children } = this.props;
    const permission = this.context;
    console.log('form :>> ', permission);

    const renderChildren = [];
    React.Children.forEach(children, child => {
      if (child.type.displayName === 'formItem') {
        const { name } = child.props;
        const newChild = React.cloneElement(child, {
          key: name,
          value: formData[name],
          handleChange: this.setValue,
        }, child.props.children);
        renderChildren.push(newChild);
      }
    });

    return renderChildren;
  }
}
Form.displayName = 'form';
Form.contextType = PermissionContext;

function Doc({ name }) {
  const [number, setNumber] = React.useState(0);
  const handleClick = () => {
    let index = 1;
    const timer = setInterval(() => {
      if (index > 5) {
        clearInterval(timer);
      }
      console.log('number :>> ', index, number + 1);
      setNumber(number + 1);
      index += 1;
    }, 1000 * index);
  };
  return (
    <div>Doc, {name}, <Button onClick={handleClick}>Click{number}</Button></div>
  );
}

function Tag({ name }) {
  return (
    <div>Tag, {name}</div>
  );
}

function WithHOC(auth) {
  return function (Component) {
    return function C(props) {
      return (
        <PermissionContext.Consumer>
          {
            permission => (
              permission.includes(auth) ? <Component {...props} /> : <>{auth}, no permission</>
            )
          }
        </PermissionContext.Consumer>
      );
    };
  };
}

const WithDoc = WithHOC('docList')(Doc);
const WithTag = WithHOC('tag')(Tag);

const Son = React.forwardRef(({ toFather }, ref) => {
  const [sonMsg, setSonMsg] = useState('');
  const [fatherMsg, setFatherMsg] = useState('');

  useImperativeHandle(ref, () => ({
    sonMsg,
    fatherSay(msg) {
      setFatherMsg(msg);
    },
  }), [sonMsg]);

  return (
    <>
      <Title>子组件</Title>
      <p>父组件对我说：{fatherMsg}</p>

      <Space>
        <Text>对父组件说：</Text>
        <Input onChange={msg => setSonMsg(msg)} />
        <Button onClick={() => toFather(sonMsg)}>to father</Button>
      </Space>
    </>
  );
});

function Father() {
  const [sonMsg, setSonMsg] = useState('');
  const [fatherMsg, setFatherMsg] = useState('');
  const son = useRef(null);

  return (
    <>
      <Title>父组件</Title>
      <p>子组件对我说：{sonMsg}</p>
      <Space>
        <Text>对子组件说：</Text>
        <Input onChange={msg => setFatherMsg(msg)} />
        <Button onClick={() => son.current.fatherSay(fatherMsg)}>to son</Button>
        <Button onClick={() => setSonMsg(son.current.sonMsg)}>get son</Button>
      </Space>
      <Son ref={son} toFather={msg => setSonMsg(msg)} />
    </>
  );
}

function Sex(props) {
  const [sex, setSex] = useState('male');

  return (
    <>
      <Radio.Group value={sex} onChange={e => setSex(e.target.value)}>
        <Radio value="male">male</Radio>
        <Radio value="female">female</Radio>
      </Radio.Group>
      {props.render(sex)}
    </>
  );
}

function User({ name, sex }) {
  return (
    <ul>
      <li>{name}</li>
      <li>{sex}</li>
    </ul>
  );
}

function WithSex(Component) {
  return function C(props) {
    return (
      <Sex render={sex => <Component {...props} sex={sex} />} />
    );
  };
}

const WithUser = WithSex(User);

function getData() {
  const arr = [];
  for (let i = 1; i <= 100; i++) {
    arr.push({
      id: i,
      name: `name${i}`,
    });
  }
  return arr;
}

function avg(arr) {
  const result = [];
  let i = 0;
  while (i < arr.length) {
    result.push(arr.slice(i, i + 10));
    i += 10;
  }
  return result;
}

class BigData extends React.Component {
  state = { list: [] };

  handleClick = () => {
    let { list } = this.state;

    const arr = avg(getData());

    const renderItem = page => {
      console.log('page :>> ', page);
      if (page > arr.length - 1) {
        return;
      }

      requestAnimationFrame(() => {
        list = [...list, ...arr[page]];
        this.setState({ list });
        page += 1;

        renderItem(page);
      });
    };

    renderItem(0);
  };

  render() {
    const { list } = this.state;

    return (
      <>
        <ul>
          {list.map(item => <li key={item.id}>{item.name}</li>)}
        </ul>
        <Button onClick={() => this.handleClick()}>Click</Button>
      </>
    );
  }
}

function Home() {
  const form = useRef(null);
  const [permission, setPermission] = useState([]);

  useEffect(() => {
    setPermission(['tagList', 'docList']);
  }, []);

  function debounce(func, delay = 1000) {
    let timer = null;
    let last = 0;
    return function (...args) {
      const now = Date.now();
      if (now - last < delay) {
        console.log('object');
        clearTimeout(timer);
        timer = setTimeout(() => {
          last = now;
          func.apply(this, args);
        }, delay);
      } else {
        last = now;
        func.apply(this, args);
      }
    };
  }

  function changeName(value) {
    console.log('value :>> ', value);
  }

  function handleScroll(e) {
    console.log('e :>> ', e);
  }

  return (
    <div onScroll={handleScroll}>
      <PermissionContext.Provider value={permission}>
        <Title>Form</Title>
        <Form ref={form}>
          <FormItem label="用户名" name="name">
            <Input placeholder="请输入用户名" />
          </FormItem>
          <FormItem label="密码" name="pwd">
            <Input placeholder="请输入密码" />
          </FormItem>
        </Form>
        <Space>
          <Button onClick={() => form.current.handleSubmit(values => console.log(values))}>submit</Button>
          <Button onClick={() => form.current.handleReset()}>reset</Button>
        </Space>

        <Title>HOC</Title>
        <>
          <WithDoc name="xiaoxin" />
          <WithTag name="xiaoqiang" />
        </>

        <Title>Ref</Title>
        <Father />

        <Title>Render Props</Title>
        <WithUser name="xiaoxin" />

        <Title>Big Data</Title>
        <BigData />

        <Title>节流防抖</Title>
        <Input onChange={debounce(changeName, 1000)} />

      </PermissionContext.Provider>
    </div>
  );
}

export default Home;
