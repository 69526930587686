import Mock from 'mockjs';
const { Random } = Mock;

export function getDeviceList(params) {
  console.log('params :>> ', params);

  const list = [];

  for (let i = (params.pageNum - 1) * params.pageSize + 1; i <= params.pageNum * params.pageSize; i++) {
    list.push({
      id: i,
      name: `设备名称${i}`,
      state: Random.integer(0, 1),
      sn: '5LM0216928005834',
      userName: Random.cname(),
      bindStatus: Random.integer(0, 1),
    });
  }

  const data = {
    list,
    total: 86,
  };

  return Promise.resolve({
    data,
    result: {
      c: 200,
      m: '操作成功',
    },
  });
}
