import React, { useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import {
  Button,
  InputNumber,
  Radio,
  Space,
  Typography,
} from 'antd';

const { Text, Title } = Typography;
const PermissionContext = React.createContext();

function Input(props) {
  const { value, placeholder, onChange } = props;
  const p = useContext(PermissionContext)
  console.log('p :>> ', p);
  return (
    <input value={value} placeholder={placeholder} onChange={e => onChange(e.target.value)} />
  )
}
Input.displayName = 'input'

function FormItem(props) {
  const { children, label, name, value, handleClick } = props;

  function onChange(value) {
    handleClick(name, value)
  }

  return (
    <div>
      <span>{label}</span>
      {
        React.isValidElement(children) && children.type.displayName === 'input'
          ? React.cloneElement(children, { value, onChange })
          : null
      }
    </div>
  )
}
FormItem.displayName = 'formItem'

class Form extends React.Component {
  state = { formData: {} }

  setValue = (key, value) => {
    const { formData } = this.state;
    formData[key] = value;
    this.setState({ formData });
  }

  handleSubmit(cb) {
    const { formData } = this.state;
    cb({ ...formData })
  }

  handleReset() {
    const { formData } = this.state;
    Object.keys(formData).forEach(key => {
      formData[key] = ''
    });
    this.setState({ formData });
  }

  render() {
    const { formData } = this.state;
    const { children } = this.props;
    const permission = this.context
    console.log('permission :>> ', permission);

    const renderChildren = []
    React.Children.forEach(children, child => {
      if (child.type.displayName === 'formItem') {
        const { name } = child.props;
        const newChild = React.cloneElement(child, {
          key: name,
          value: formData[name],
          handleClick: this.setValue
        }, child.props.children)

        renderChildren.push(newChild)
      }
    });

    return renderChildren
  }
}
Form.displayName = 'form';
Form.contextType = PermissionContext

function Tag({ name }) {
  return (
    <div>Tag, {name}</div>
  )
}

function Doc({ name }) {
  return (
    <div>Doc, {name}</div>
  )
}

function WithHOC(auth) {
  return function (Component) {
    return function C(props) {
      return (
        <PermissionContext.Consumer>
          {
            permission => (
              permission.includes(auth) ? <Component {...props} /> : <span>{auth}, no permission</span>
            )
          }
        </PermissionContext.Consumer>
      )
    }
  }
}

const WithTag = WithHOC('tagList')(Tag)
const WithDoc = WithHOC('docList')(Doc)

function Sex(props) {
  const [sex, setSex] = useState('male');
  return (
    <>
      <Radio.Group value={sex} onChange={e => setSex(e.target.value)}>
        <Radio value="male">male</Radio>
        <Radio value="female">female</Radio>
      </Radio.Group>
      {props.render(sex)}
    </>
  )
}

function User({ name, sex }) {
  return (
    <ul>
      <li>{name}</li>
      <li>{sex}</li>
    </ul>
  )
}

function WithSex(Component) {
  return function C(props) {
    return (
      <Sex render={sex => <Component sex={sex} {...props} />} />
    )
  }
}

const WithUser = WithSex(User);

function Father() {
  const son = useRef(null);

  const [sonMsg, setSonMsg] = useState('')
  const [fatherMsg, setFatherMsg] = useState('')

  return (
    <>
      <Title>父组件</Title>
      <p>子组件对我说：{sonMsg}</p>
      <Space>
        <Text>对子组件说：</Text>  
        <Input onChange={value => setFatherMsg(value)} />
        <Button onClick={() => son.current.fatherSay(fatherMsg)}>to son</Button>
        <Button onClick={() => setSonMsg(son.current.sonMsg)}>get son</Button>
      </Space> 

      <Son ref={son} toFather={msg => setSonMsg(msg)} />
    </>
  )
}

const Son = React.forwardRef(({ toFather }, ref) => {
  const [sonMsg, setSonMsg] = useState('')
  const [fatherMsg, setFatherMsg] = useState('')

  useImperativeHandle(ref, () => ({
    sonMsg,
    fatherSay(msg) {
      setFatherMsg(msg)
    }
  }), [sonMsg])

  return (
    <>
    <Title>子组件</Title>
    <p>父组件对我说：{fatherMsg}</p>
    <Space>
      <Text>对父组件说：</Text>  
      <Input onChange={value => setSonMsg(value)} />
      <Button onClick={() => toFather(sonMsg)}>to father</Button>
    </Space> 
  </>
  )
})

function Welcome() {
  const form = useRef(null);

  const [permission, setPermission] = useState([])

  useEffect(() => {
    setPermission(['tagList', 'docList'])
  }, [])

  function handleFatherClick() {
    console.log('father click');
  }

  function handleClick(e) {
    e.stopPropagation();
    e.preventDefault();
    console.log('click');
  }

  return (
    <PermissionContext.Provider value={permission}>
      <Title className="name">Welcome Learn!!!</Title>

      <div onClick={handleFatherClick}>
        <Button onClick={handleClick}>冒泡测试</Button>
        <Button type="link" href="www.baidu.com" onClick={handleClick}>冒泡测试</Button>
        <InputNumber min={1} addonAfter="天" />
      </div>

      <Title>Form</Title>
      <Form ref={form}>
        <FormItem label="姓名" name="name">
          <Input placeholder="请输入姓名" />
        </FormItem>
        <FormItem label="年龄" name="age">
          <Input placeholder="请输入年龄" />
        </FormItem>
      </Form>
      <Space>
        <Button onClick={() => form.current.handleSubmit(values => console.log('values :>> ', values))}>submit</Button>
        <Button onClick={() => form.current.handleReset()}>reset</Button>
      </Space>

      <Title>HOC</Title>
      <WithTag name="xiaoxin" />
      <WithDoc name="xiaoqiang" />

      <Title>Render Props</Title>
      <WithUser name="xiaoxin" />

      <Title>Ref</Title>
      <Father />
    </PermissionContext.Provider>
  );
}

export default Welcome;
