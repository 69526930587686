/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Card,
  Form,
  Input,
  Modal,
  Select,
} from 'antd';

const typeOptions = [
  {
    label: '布控球',
    value: 0,
  },
  {
    label: '执法仪',
    value: 1,
  },
  {
    label: '单兵',
    value: 2,
  },
  {
    label: '手持设备',
    value: 3,
  },
  {
    label: '无人机',
    value: 4,
  },
];

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

function AddModal({
  children, record = {}, refresh,
}) {
  const [form] = Form.useForm();
  const [isOpen, setIsOpen] = useState(false);

  if (Object.keys(record).length) {
    form.setFieldsValue({
      deviceType: 1,
      deviceName: record.name,
      deviceId: record.id,
      serialNo: record.sn,
      assetNo: record.sn,
      videoChannel: record.sn,
    });
  } else {
    form.resetFields();
  }

  const handleOk = () => {
    form.validateFields().then(values => {
      if (Object.keys(record).length) {
        values.id = record.id;
      }

      console.log('values :>> ', values);

      setIsOpen(false);
      form.resetFields();
      refresh();
    });
  };

  return (
    <>
      <div onClick={() => setIsOpen(true)}>{children}</div>
      <Modal
        open={isOpen}
        title={record.id ? '编辑设备' : '添加设备'}
        width={800}
        cancelText="取消"
        okText="提交"
        onCancel={() => setIsOpen(false)}
        onOk={handleOk}
      >
        <Card bordered={false}>
          <Form
            {...formItemLayout}
            form={form}
          >
            <Form.Item
              label="设备类型"
              name="deviceType"
              rules={[
                {
                  required: true,
                  message: '请选择设备类型',
                },
              ]}
            >
              <Select
                allowClear
                placeholder="选择设备类型"
                options={typeOptions}
              />
            </Form.Item>
            <Form.Item
              label="设备名称"
              name="deviceName"
              rules={[
                {
                  required: true,
                  message: '设备名称不能为空',
                },
              ]}
            >
              <Input allowClear placeholder="请输入设备名称" />
            </Form.Item>
            <Form.Item
              label="设备编号"
              name="deviceId"
              rules={[
                {
                  required: true,
                  message: '设备编号不能为空',
                },
                {
                  len: 20,
                  message: '设备编号长度为20位',
                },
              ]}
            >
              <Input
                allowClear
                placeholder="请输入设备编号"
                maxLength={20}
                addonAfter="长度20位"
              />
            </Form.Item>
            <Form.Item
              label="设备序列号"
              name="serialNo"
              rules={[
                {
                  required: true,
                  message: '设备序列号不能为空',
                },
              ]}
            >
              <Input allowClear placeholder="请输入设备序列号" />
            </Form.Item>
            <Form.Item
              label="资产编号"
              name="assetNo"
              rules={[
                {
                  required: true,
                  message: '资产编号不能为空',
                },
              ]}
            >
              <Input allowClear placeholder="请输入资产编号" />
            </Form.Item>
            <Form.Item
              label="国标视频通道编号"
              name="videoChannel"
              rules={[
                {
                  required: true,
                  message: '国标视频通道编号不能为空',
                },
                {
                  len: 20,
                  message: '设备编号长度为20位',
                },
              ]}
            >
              <Input
                allowClear
                placeholder="请输入国标视频通道编号"
                maxLength={20}
                addonAfter="长度20位"
              />
            </Form.Item>
          </Form>
        </Card>
      </Modal>
    </>
  );
}

export default AddModal;
