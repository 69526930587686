import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  Card,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Space,
  Table,
  Tag,
  Typography,
} from 'antd';

import qs from 'qs';

import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { filterEmptyObj } from '../utils/utils';

import { getDeviceList } from '../../services/device';

import AddModal from './AddModal';

const typeOptions = [
  {
    label: '布控球',
    value: 0,
  },
  {
    label: '执法仪',
    value: 1,
  },
  {
    label: '单兵',
    value: 2,
  },
  {
    label: '手持设备',
    value: 3,
  },
];

const deviceStatusOptions = [
  {
    label: '全部',
    value: -1,
  },
  {
    label: '离线',
    value: 0,
  },
  {
    label: '在线',
    value: 1,
  },
];

function Device() {
  const [form] = Form.useForm();
  const [type, setType] = useState(0);
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);

  const [modal, contextHolder] = Modal.useModal();

  const { pathname, search } = window.location;
  const navigate = useNavigate();

  const query = qs.parse(search, { ignoreQueryPrefix: true });
  query.type = query.type ? Number(query.type) : 0;
  query.deviceStatus = query.deviceStatus ? Number(query.deviceStatus) : -1;
  query.pageNum = query.pageNum ? Number(query.pageNum) : 1;
  query.pageSize = query.pageSize ? Number(query.pageSize) : 10;

  useEffect(() => {
    form.setFieldsValue(query);
    setType(query.type);
  }, [query]);

  const fetchDeviceList = async () => {
    const params = {
      pageNum: query.pageNum,
      pageSize: query.pageSize,
      ...query,
    };

    const res = await getDeviceList(params);
    setList(res?.data?.list);
    setTotal(res?.data?.total);
  };

  useEffect(() => {
    fetchDeviceList();
  }, [search]);

  const handleChangeType = ({ target: { value } }) => {
    const params = qs.stringify(filterEmptyObj({
      ...query,
      type: value,
      pageNum: 1,
      pageSize: 10,
    }));
    navigate(`${pathname}?${params}`);
  };

  const handleReset = () => {
    form.resetFields();
    navigate(`${pathname}?type=${type}`);
  };

  const handleSearch = values => {
    const params = qs.stringify(filterEmptyObj({
      pageNum: 1,
      pageSize: 10,
      type,
      ...values,
    }));
    navigate(`${pathname}?${params}`);
  };

  const refresh = () => {
    console.log('refresh');
    navigate(pathname);
  };

  const deleteDevice = id => {
    console.log('delete :>> ', id);
  };

  const handleDelete = id => {
    modal.confirm({
      title: '操作提示',
      icon: <ExclamationCircleOutlined />,
      content: <div style={{ marginTop: 16 }}>确定要删除这条设备信息吗？</div>,
      okText: '确认',
      cancelText: '取消',
      onOk: () => deleteDevice(id),
    });
  };

  const unBindDevice = id => {
    console.log('unbind :>> ', id);
  };

  const handleUnBind = id => {
    modal.confirm({
      title: '操作提示',
      icon: <ExclamationCircleOutlined />,
      content: <div style={{ marginTop: 16 }}>确定解绑该设备吗？</div>,
      okText: '确认',
      cancelText: '取消',
      onOk: () => unBindDevice(id),
    });
  };

  const handlePageChange = ({ current, pageSize }) => {
    const params = qs.stringify(filterEmptyObj({
      ...query,
      pageNum: current,
      pageSize,
    }));
    navigate(`${pathname}?${params}`);
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: '设备名称',
      dataIndex: 'name',
    },
    {
      title: '在线状态',
      dataIndex: 'state',
      render: state => <Tag color={state === 1 ? '#87d068' : '#f50'}>{state === 1 ? '在线' : '离线'}</Tag>,
    },
    {
      title: '设备序列号',
      dataIndex: 'sn',
    },
    {
      title: '绑定管理',
      dataIndex: 'id',
      render: (id, record) => (
        record.bindStatus === 1
          ? (
            <a className="link" onClick={() => handleUnBind(id)}>
            解绑
            </a>
          )
          : (
            <a className="link" onClick={() => handleUnBind(id)}>
            绑定
            </a>
          )
      ),
    },
    {
      title: '使用人',
      dataIndex: 'userName',
    },
    {
      title: '操作',
      dataIndex: 'id',
      render: (id, record) => (
        <Space>
          <a className="link" onClick={() => handleDelete(id)}>删除</a>
          <AddModal refresh={refresh} record={record}>
            <a className="link">编辑</a>
          </AddModal>
        </Space>
      ),
    },
  ].filter(item => type !== 3 || (type === 3 && !['绑定管理', '操作'].includes(item.title)));

  const paginationProps = {
    showQuickJumper: true,
    showTotal: () => `共${total}条`,
    current: query.pageNum,
    pageSize: query.pageSize,
    total,
  };

  return (
    <>
      <Typography.Title>Device List</Typography.Title>

      <Card
        title={
          <Radio.Group
            value={type}
            optionType="button"
            buttonStyle="solid"
            options={typeOptions}
            onChange={handleChangeType}
          />
        }
        extra={
          <AddModal refresh={refresh}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
            >
              添加设备
            </Button>
          </AddModal>
        }
      >
        <Form form={form} onFinish={handleSearch}>
          <Space>
            <Form.Item label="状态" name="deviceStatus">
              <Select
                style={{ width: 120 }}
                options={deviceStatusOptions}
              />
            </Form.Item>
            <Form.Item label="设备名称" name="name">
              <Input
                allowClear
                placeholder="请输入"
              />
            </Form.Item>
            <Form.Item>
              <Space>
                <Button onClick={handleReset}>重置</Button>
                <Button type="primary" htmlType="submit">查询</Button>
              </Space>
            </Form.Item>
          </Space>
        </Form>

        <Table
          rowKey="id"
          columns={columns}
          dataSource={list}
          style={{ marginTop: 16 }}
          pagination={paginationProps}
          onChange={handlePageChange}
        />

        {contextHolder}
      </Card>
    </>
  );
}

export default Device;
